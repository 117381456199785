import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import PosTabWrapper from "../../components/PosTabWrapper";
import PosCategoryColumnWrapper from "../../components/PosCategoryColumnWrapper";
import { ReactComponent as CirclePlus } from "../../assets/img//circle-plus.svg";
import PosCategoryColumn from "../../components/PosCategoryColumnWrapper/PosCategoryColumn";
import Card from "../../components/Card";
import { getPlaceInfoAxios } from "../../api/place";
import { BusinessInfoInterface } from "../../types/place";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { posCategoryItem, posStampCategoryRequest } from "../../types/posRyles";
import {
   editStampCategoryAxios,
  getAllCategoriesAxios,
  getPosStampCategoryAxios, removeStampCategoryAxios,
  setPosStampCategoryAxios
} from "../../api/posTerminals";
import { getStampIconsAxios } from "../Registration/Step/RegistrationBuildYourCard/api";
import { stampIcon } from "../../types/stampIcon";
import DataTable from "react-data-table-component";
import { ReactComponent as CheckCircle } from "../../assets/img/checkCircle.svg";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Delete } from "../../assets/img/delete.svg";
import InnerPosStampCategoryGroup
  from "../../components/PosCategoryColumnWrapper/innerPosStampCategory/InnerPosStampCategoryGroup";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const PosRules = () => {
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const [cardClosed, setCardClosed] = useState<boolean>(false);
  const [businessLogo, setBusinessLogo] = useState<string>('');
  const [items, setItems] = useState<posCategoryItem[]>([]);
  const [stampIconList, setStampIconList] = useState<any[]>([]);
  const [posStamps, setPosStamps] = useState<posStampCategoryRequest[]>([{
    name: '',
    product_group: '',
    product_sku: '',
    icon: 'coffee_cup',
    target: 0
  }])
  const [selectedStampCategory, setSelectedStampCategory] = useState<posStampCategoryRequest>(posStamps[0])
  const [edit, setEdit] = useState(false)
  const [editStamp, setEditStamp] = useState(false)
  const [businessInfo, setBusinessInfo] = React.useState<BusinessInfoInterface>(
    {
      name: "",
      category: "",
      promotionName: "",
      promotionTypeName: "",
      promotionVaucherInformation: "",
      promotionTypeID: 0,
      maxNumberPromotion: 0,
      verification_status: null,
      promotionLabel: null,
      businessType: {
        display_name: null,
        id: null,
        name: null,
      },
      stampIcon: {
        display_name: null,
        id: null,
        name: null,
        single_item: null,
      },
      points: [],
      website_url: "",
      instagram_url: "",
      facebook_url: "",
      first_checkIn: '',
      bonusRewardTarget: 0,
      bonusRewardValue: 0,
      bonusRewardLabel: '',
      bonusRewardExpireDays: '',
    }
  );
  useEffect(() => {
    getAllCategoriesAxios().then((res)=>{
      setItems(res);
    })
    getPosStampCategories()
    handleGetStampIcons()

    // setItems([...defItems])
  },[])


  const handleGetStampIcons = () => {
    getStampIconsAxios().then((response) => {
      setStampIconList(
        response.data.stampIcons.map((_stampIcon: stampIcon) => {
          return {
            value: _stampIcon.name,
            label: _stampIcon.display_name,
            additional: {
              icon_name: _stampIcon.name,
              single_item: _stampIcon.single_item,
            },
          };
        })
      );
    });
  };

  useEffect(() => {
    getPlaceInfo();
  }, [items]);

  const getPlaceInfo = () => {
    getPlaceInfoAxios().then(({ data }) => {
      // setGetInfoLoadingLoading(false);
      setBusinessLogo(data.place.image);
      setBusinessInfo({
        ...businessInfo,
        name: data.place.name,
        category: data.place.category,
        promotionName: data.place.details,
        promotionVaucherInformation: data.place.description,
        promotionTypeName: data.place.promotion,
        promotionTypeID: data.place.promotion_id,
        maxNumberPromotion: data.place.maxStamp,
        verification_status: data.place.verification_status,
        promotionLabel: data.place.promotion_label,
        businessType: {
          display_name: data.place.business_type.display_name,
          id: data.place.business_type.id,
          name: data.place.business_type.name,
        },
        stampIcon: {
          display_name: data.place.stamp_icon?.display_name,
          id: data.place.stamp_icon?.id,
          name: data.place.stamp_icon?.name,
          single_item: data.place.stamp_icon?.single_item,
        },
        points: data.place.points,
        website_url: data.place.website_url,
        instagram_url: data.place.instagram_url,
        facebook_url: data.place.facebook_url,
        first_checkIn: data.place.first_checkIn
      });
      // setLocations(data.locations);
      // setLocationMaxCount(data.location_max_count);
    });
  };

  const getAllCategories: any = async () => {
    await getAllCategoriesAxios().then((res)=>{
      return setItems(res);
    })
  }
  const getPosStampCategories: any = async () => {

    await getPosStampCategoryAxios().then((res)=>{
      return res.data !== null ? setPosStamps(res.data) : setPosStamps([{
        name: '',
        product_group: '',
        product_sku: '',
        icon: 'coffee_cup',
        target: 0,
      }])
    })

  }
  const validInputTextValue = [
    '', 0, 0.0,'0'
  ]

  const addPosStampCategory = () => {
    const newStampCategoryItem:posStampCategoryRequest = {
      name: '',
      product_group: '',
      product_sku: '',
      icon: 'coffee_cup',
      target: 0,
      edit: true,
      children: [],
    }
    setPosStamps([...posStamps, newStampCategoryItem])
    setEditStamp(true)
  }

  const addNewCategory = () => {
    const newItem:posCategoryItem = {
        edit: true,
        name: '',
        product_group: '',
        product_sku: '',
        scale_parameter: 'price',
        scale_factor: '0',
        transaction_value: 0,
        currency: loggedUser.user.currency_symbol || '€',
        allocated: '',
        children: []
    }

    setItems(items => items = [...items, newItem])
    setEdit(true)

  }

  const saveEditStampCategory = (id: number) =>{
    const newItems = posStamps.filter(item => item.id === id)[0];
    newItems.edit = true;
    if(validInputTextValue.includes(newItems.name) || validInputTextValue.includes(newItems.product_group) || validInputTextValue.includes(newItems.target)) {
      return
    }

    const requestItem:posStampCategoryRequest  = {
      id: newItems.id,
      name: newItems.name,
      product_group: newItems.product_group,
      product_sku: newItems.product_sku,
      icon: `${newItems.icon}`,
      target: newItems.target,
      parent: ''
    }
    setEditStamp(false);
    editStampCategoryAxios(requestItem).then(()=>{
      getPosStampCategories();
    })
  }

  const addNewPosStampCategory = (index: number) => {
    const newItems = posStamps[index];
    newItems.edit = false;
    if(validInputTextValue.includes(newItems.name) || validInputTextValue.includes(newItems.product_group) || validInputTextValue.includes(newItems.target)) {
      return
    }

    const requestItem:posStampCategoryRequest  = {
      name: newItems.name,
      product_group: newItems.product_group,
      product_sku: newItems.product_sku,
      icon: `${newItems.icon}`,
      target: newItems.target,
      parent: ''
    }
    setEditStamp(true)
    setPosStampCategoryAxios(requestItem).then(() => {
      getPosStampCategories();
    }).catch(e => {
      console.log(e);
    });
  }

  const editPosStampCategoryItem = (id: number) => {
    const newItems = posStamps;
    newItems[id].edit = true;
    setPosStamps((stamp: Array<any>) => stamp = [...newItems]);
    setEditStamp(true)
  };

  const removePosStampCategoryItem = (id: number | undefined) => {
    removeStampCategoryAxios(`${id}`)
      .then((res) => {
        getPosStampCategories();
      }).catch(e => {
      console.log(e);
    });
    setPosStamps((items: Array<any>) => items.filter((category) => category.id !== id));
  };

  const selectStampCategory = (row:any) => {
    setSelectedStampCategory(row)
  }

  const categoryList = [
    {
      name: "",
      width: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (
          <Input
            defaultValue={item.name}
            required
            getValue={(value) => item.name = value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (
          <Input
            defaultValue={item.product_group}
            required={item.product_sku === ''}
            getValue={(value) => item.product_group = value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (
          <Input
            defaultValue={item.product_sku}
            required={item.product_group === ''}
            getValue={(value) => item.product_sku = value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (<Select
            placeholder="Select stamp Icon"
            selectorList={stampIconList}
            positionForShow="bottom"
            required
            defaultValue={stampIconList.filter(stamp => stamp.value === item.icon)[0] || stampIconList[0]}
            getValue={(value) => item.icon = value}
            hideSelectorFilter
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (
          <Input
            defaultValue={item.target ? item.target : '0'}
            type="number"
            required
            getValue={(value: string) => item.target = +value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "7.5%",
      cell: (item: any, index: number): JSX.Element => {

        return (
          <div className="buttons" data-tag="allowRowEvents">
            {item.edit && item.id ?
              <button className="btn-white edit-category save-btn" onClick={() => saveEditStampCategory(item.id)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
                <span>Save</span>
              </button>
              : item.edit ? <button className="btn-white edit-category save-btn" onClick={() => addNewPosStampCategory(index)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
                <span>Save</span>
              </button> :  <>
                <button disabled={edit} className="btn-white edit-category" onClick={() => editPosStampCategoryItem(index)}>
                      <span className="icon">
                        <Edit />
                      </span>
                </button>
                <button disabled={edit} className="btn-white remove-category" onClick={() => removePosStampCategoryItem(item.id)}>
                      <span className="icon">
                      <Delete />
                      </span>
                </button>
              </>}

          </div>
        );
      }
    }
  ];
  return (
    <Wrapper>
      <PosTabWrapper title={"Customize Loyalty Based on Your Business Need"}
                     description={"Use this section to create specific rules related to specific products and product groups, such as: restricted product lines where no loyalty points are issued, double points on promotional lines, or scaled down points based on spending"}
                     button={true} buttonText={"Upload SKU Inventory"}>
        <div className="content">
          <PosCategoryColumnWrapper items={items} setItems={setItems} edit={edit} setEdit={setEdit} getAllCategories={getAllCategories} />
          <button disabled={edit} className="btn-white add-category" onClick={() => addNewCategory()}>
            <span className="icon">
              <CirclePlus/>
            </span>
              <span>Add Category</span>
          </button>
        </div>
      </PosTabWrapper>

      <PosTabWrapper title={"Stamp Based Loyalty"}
                     description={"You can offer stamp based loyalty to your customers on specific product groups, e.g Coffee: Buy 5 get 1 free."}
                     button={false}>
        <>
          <div className="content stamp-based-wrapper">
            <div className="header">
              <div className="header-tab">
                <div style={{width: '5%'}}></div>
                <PosCategoryColumn title={'Loyalty Category'} description={'Name your product category. e.g Fue'}/>
                <PosCategoryColumn title={'Product Group'}
                                   description={'Enter your group I.D processed through your POS'}/>
                <PosCategoryColumn title={'Product SKU'}
                                   description={'Enter your product SKU processed through your POS'}/>
                <PosCategoryColumn title={'Stamp Icon'} description={'Select preferred stamp icon, e.g Coffee Cup'}/>
                <PosCategoryColumn title={'Stamp Target'}
                                   description={'Enter the number of stamps a customer must earn for a free item'}/>
                <div className="column_buttons"></div>
              
              </div>
              
              
              <div className="stamp-based-form">

                {posStamps !== null ? (
                  posStamps.length > 0 ? (
                    <>
                      {editStamp ? <div className="table table-edit">
                        <DataTable
                          noTableHead
                          noHeader
                          columns={categoryList}
                          onRowClicked={(row) => selectStampCategory(row)}
                          data={posStamps}
                          expandableRows
                          expandableRowsComponent={
                            <InnerPosStampCategoryGroup selectStampCategory={selectStampCategory} data={items} edit={editStamp} setEdit={setEditStamp} getAllCategories={getPosStampCategories} setItems={setPosStamps} scaleParemeterSelectList={stampIconList}/>
                          }
                        />
                      </div> : <div className="table">
                      <DataTable
                          noTableHead
                          noHeader
                          columns={categoryList}
                          data={posStamps}
                          onRowClicked={(row) => selectStampCategory(row)}
                          expandableRows
                          expandableRowsComponent={
                            <InnerPosStampCategoryGroup selectStampCategory={selectStampCategory} data={items} edit={editStamp} setEdit={setEditStamp} getAllCategories={getPosStampCategories} setItems={setPosStamps} scaleParemeterSelectList={stampIconList}/>
                          }
                        />
                      </div>}

                    </>
                  ) : (
                    <div className="nothing-found">Sorry, nothing found</div>
                  )
                ) : null}
              </div>
              <button disabled={edit} className="btn-white add-stampCategory" onClick={() => addPosStampCategory()}>
            <span className="icon">
              <CirclePlus/>
            </span>
                <span>Add Stamp Category</span>
              </button>
            </div>
            <Card
              acceptedFiles={businessLogo}
              accountInformation={{
                companyName: businessInfo.name,
                businessType: businessInfo.businessType?.id ?? "",
                stampIcon: businessInfo.stampIcon?.id ?? "",
                stampName: `${selectedStampCategory.icon}`,
                promotionType: 1,
                rewardInformation: `Free ${selectedStampCategory.name}`,
                rewardCode: "",
                maxNumberPromotion: +selectedStampCategory.target,
                promotionLabel: businessInfo.promotionLabel ?? "",
                first_checkIn: +businessInfo.first_checkIn ?? '0'
              }}
              cardClosed={cardClosed}
              setCardClosed={setCardClosed}
              points_info={{
                maxPoints: businessInfo.points
                  ? businessInfo.points[0]?.pointsAmount
                  : undefined,
                points: businessInfo.points
                  ? businessInfo.points[0]?.pointsAmount / 2
                  : undefined,
                reward: businessInfo.points
                  ? businessInfo.points[0]?.reward
                  : undefined,
              }}
            />

          </div>

        </>

      </PosTabWrapper>

    </Wrapper>
  );
};

export default PosRules;
