import React, {useEffect, useState} from "react";
import DataTable from "react-data-table-component";

import СheckboxTable from "src/components/СheckboxTable";
import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import {ReactComponent as InfoIcon} from "src/assets/img/info.svg";
import {ReactComponent as SearchIcon} from "../../../../assets/img/search.svg";
import {ReactComponent as DeleteIcon} from "../../../../assets/img/delete.svg";
import {Wrapper as WrapperTooltip} from "src/components/infoTooltip/styled";
import Select from "src/components/Select";
import {
  createCustomerGroupForProductDiscountAxios,
  getCustomerGroupInfoForProductDiscountAxios,
  getCustomerListAxios,
  updateCustomerGroupForProductDiscountAxios,
} from "src/api/customers";
import {CustomerInfoInterface, CustomerInterface, customerPerPage,} from "src/types/customer";
import userDefaultImg from "src/assets/img/default-user.jpg";
import {ReactComponent as ArrowBackIcon} from "src/assets/img/arrowBack.svg";
import {ReactComponent as ArrowForwardIcon} from "src/assets/img/arrowForward.svg";

import {Wrapper} from "./styled";
import Checkbox from "../../../../components/CheckBox";

interface ICustomerGroups {
  setShowCreateGroupForm: (showCreateGroupForm: boolean) => void;
  getAllCustomersGroupForProductDiscount: () => void;
  setSelectedGroup: (group: any) => void;
  selectedGroup: any;
}

const CustomerGroups = ({
                          setShowCreateGroupForm,
                          getAllCustomersGroupForProductDiscount,
                          selectedGroup, setSelectedGroup
                        }: ICustomerGroups) => {
  const [pageEditLoading, setPageEditLoading] = React.useState<boolean>(true);
  const [pageEdit, setPageEdit] = React.useState<boolean>(!!selectedGroup);
  const [customerGroup, setCustomerGroup] =
    useState<any>({
      group_name: "",
      group_description: "",
      group_discount: "",
    });
  
  const handlesetCustomerGroup = (name: string, value: string | number | null) => {
    setCustomerGroup((prevState: any) => {
      return {...prevState, [name]: value};
    });
  };
  
  /*   manual customer list   */
  const [manualShowAllCustomer, setManualShowAllCustomer] =
    React.useState<boolean>(true);
  const [manualCustomerListLoading, setManualCustomerListLoading] =
    React.useState<boolean>(false);
  const [searchCustomers, setSearchCustomers] = useState<string>('');
  const [manualCustomerList, setManualCustomerList] = React.useState<
    CustomerInterface[]
  >([]);
  const [manualCustomerChoosedList, setManualCustomerChoosedList] =
    React.useState<CustomerInterface[]>([]);
  const [manualCustomerListInfo, setManualCustomerListInfo] =
    React.useState<CustomerInfoInterface>({
      count_users: 0,
      limit: 10,
      page: 1,
      total_pages_count: 0,
      total_users_count: 0,
      // searchType: 0,
    });
  const [manualCustomerAllPageChecked, setManualCustomerAllPageChecked] =
    React.useState<boolean>(false);
  const checkCheckedManualAllUser = () => {
    return false;
  };
  const toggleManualCheckedAllUser = () => {
    if (manualCustomerAllPageChecked) {
      manualCustomerList.forEach((currentValue) => {
        const index = manualCustomerChoosedList.findIndex(
          (item) => item.id === currentValue.id
        );
        if (index >= 0) {
          setManualCustomerChoosedList((prevState) =>
            prevState.filter((item) => item.id !== currentValue.id)
          );
        }
      });
    } else {
      manualCustomerList.forEach((currentValue) => {
        const index = manualCustomerChoosedList.findIndex(
          (item) => item.id === currentValue.id
        );
        if (index < 0) {
          setManualCustomerChoosedList((prevState) => [
            ...prevState,
            currentValue,
          ]);
        }
      });
    }
    setManualCustomerAllPageChecked(!manualCustomerAllPageChecked);
  };
  const getManualCustomerList = () => {
    setManualCustomerListLoading(true);
    const requestParamtrs = {
      limit: manualCustomerListInfo.limit.toString(),
      page: manualCustomerListInfo.page.toString(),
      startDate: new Date(2022, 0, 1),
      endDate: new Date(),
      search: searchCustomers
    };
    getCustomerListAxios(requestParamtrs).then((response) => {
      setManualCustomerList(response.data.users);
      setManualCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      setManualCustomerListLoading(false);
    });
  };
  const handleManualCustomerPagination = (page: number) => {
    setManualCustomerListLoading(true);
    const requestParamtrs = {
      limit: manualCustomerListInfo.limit.toString(),
      page: page.toString(),
      startDate: new Date(2022, 0, 1),
      endDate: new Date(),
      search: searchCustomers
    };
    getCustomerListAxios(requestParamtrs).then((response) => {
      setManualCustomerList(response.data.users);
      setManualCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      checkCheckedManualAllUser();
      setManualCustomerListLoading(false);
    });
  };
  const handleManualCustomerPerPage = (perPage: string) => {
    setManualCustomerListLoading(true);
    const requestParamtrs = {
      limit: perPage,
      page: manualCustomerListInfo.page.toString(),
      startDate: new Date(2022, 0, 1),
      endDate: new Date(),
      search: searchCustomers
    };
    getCustomerListAxios(requestParamtrs).then((response) => {
      setManualCustomerList(response.data.users);
      setManualCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      checkCheckedManualAllUser();
      setManualCustomerListLoading(false);
    });
  };
  const toggleManualCheckedUser = (user: CustomerInterface) => {
    const index = manualCustomerChoosedList.findIndex(
      (item) => +item.id === +user.id
    );
    if (index < 0) {
      setManualCustomerChoosedList((prevState) => [...prevState, user]);
    } else {
      setManualCustomerChoosedList((prevState) =>
        prevState.filter((item) => +item.id !== +user.id)
      );
      setManualCustomerAllPageChecked(false);
    }
  };
  const checkCheckedManualUser = (userId: number) => {
    const index = manualCustomerChoosedList.findIndex(
      (item) => {
        return +item.id === +userId
      }
    );
    return !(index < 0);
  };
  
  /*   Get Data For Edit   */
  const getDataForEditCustomerGroup = () => {
    if (selectedGroup?.id) {
      setPageEdit(true);
      getCustomerGroupInfoForProductDiscountAxios(selectedGroup.id).then((response) => {
        setCustomerGroup({
          group_name: response.data.data.name,
          group_description: response.data.data.description,
          group_discount: response.data.data.discount,
        });
        setManualCustomerChoosedList(response.data.data.users);
        setManualShowAllCustomer(false);
        setPageEditLoading(false);
      });
    } else {
      setPageEditLoading(false);
    }
  };
  
  /* get Data */
  useEffect(() => {
    getDataForEditCustomerGroup();
  }, []);// eslint-disable-line
  
  useEffect(() => {
    getManualCustomerList()
  }, [searchCustomers]); // eslint-disable-line
  
  const manualCustomerTableColumns = [
    {
      name: (
        <СheckboxTable
          checked={manualCustomerAllPageChecked}
          toggleChecked={() => toggleManualCheckedAllUser()}
        />
      ),
      minWidth: "50px",
      maxWidth: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <label htmlFor={`user_${row.id}`} className="checkbox">
            <input
              type="checkbox"
              id={`user_${row.id}`}
              checked={checkCheckedManualUser(row.id)}
              onChange={() => toggleManualCheckedUser(row)}
            />
            <span className="checkbox-view"/>
          </label>
        );
      },
    },
    {
      name: "",
      width: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt=""/>
            ) : (
              <img src={userDefaultImg} alt=""/>
            )}
          </div>
        );
      },
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div>
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },
    {
      name: "Email",
      minWidth: "225px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.email}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "175px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.phone}</div>;
      },
    },
    {
      name: "Points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.end_value ?? "-"}</div>;
      },
    },
    {
      name: "Lifetime points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.total_spend ?? "-"}</div>;
      },
    },
    {
      name: "Check in",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.check_in_count ?? "-"}</div>;
      },
    },
    {
      name: "Last visit",
      minWidth: "120px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{new Date(row.last_visit).toLocaleString("en-GB")}</div>;
      },
    },
  ];
  
  const manualChoosedCustomerTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt=""/>
            ) : (
              <img src={userDefaultImg} alt=""/>
            )}
          </div>
        );
      },
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div>
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },
    {
      name: "Email",
      minWidth: "225px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.email}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "175px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.phone}</div>;
      },
    },
    {
      name: "Points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.end_value ?? "-"}</div>;
      },
    },
    {
      name: "Lifetime points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.total_spend ?? "-"}</div>;
      },
    },
    {
      name: "Check in",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.check_in_count ?? "-"}</div>;
      },
    },
    {
      name: "Last visit",
      minWidth: "120px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{new Date(row.last_visit).toLocaleString("en-GB")}</div>;
      },
    }, {
      name: "",
      minWidth: "50px",
      maxWidth: "60px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div className="action">
          <button
            type="button"
            className="delete"
            onClick={() => removeChoosedUser(row.id)}
          >
            <DeleteIcon/>
          </button>
        </div>;
      },
    },
  ];
  
  const removeChoosedUser = (id: number) => {
    setManualCustomerChoosedList([...manualCustomerChoosedList.filter(user => user.id !== id)])
  }
  
  
  /*   Form   */
  const [customerGroupFormLoading, setCustomerGroupFormLoading] =
    React.useState<boolean>(false);
  const [customerGroupFormErrorMessage, setCustomerGroupFormErrorMessage] =
    React.useState<string>("");
  const handleCustomerGroupForm = (event: React.FormEvent) => {
    event.preventDefault();
    setCustomerGroupFormLoading(true);
    const requestParamtrst: any = {
      group_name: customerGroup.group_name,
      group_description: customerGroup.group_description,
      group_discount: customerGroup.group_discount ? customerGroup.group_discount : 0,
    };
    const usersList: any[] = [];
    manualCustomerChoosedList.forEach((currentValue) => {
      usersList.push(+currentValue.id);
    });
    requestParamtrst.users = Array.from(new Set(usersList))
    if (pageEdit) {
      requestParamtrst.id = selectedGroup.id;
      updateCustomerGroupForProductDiscountAxios(requestParamtrst)
        .then(() => {
          setShowCreateGroupForm(false)
          getAllCustomersGroupForProductDiscount()
        })
        .catch((error) => {
          setCustomerGroupFormLoading(false);
          setCustomerGroupFormErrorMessage(error.data.message);
        });
    } else {
      createCustomerGroupForProductDiscountAxios(requestParamtrst)
        .then(() => {
          setShowCreateGroupForm(false)
          getAllCustomersGroupForProductDiscount()
        })
        .catch((error) => {
          setCustomerGroupFormLoading(false);
          setCustomerGroupFormErrorMessage(error.data.message);
        });
    }
  };
  
  return (
    <Wrapper>
      <div className="page-title">
        {pageEdit ? "Edit" : "Create"} Customer Group
        {!pageEdit ? (
          <WrapperTooltip>
            <span className="info-tooltip" style={{marginTop: -10}}>
              <InfoIcon/>
              <span className="tooltip hiddeArrow">
                You can create a customer group within your customer base with
                certain criteria to create targeted promotions.
              </span>
            </span>
          </WrapperTooltip>
        ) : (
          ""
        )}
      </div>
      <div
        className={`content ${
          pageEditLoading ? "loading-blue" : ""
        }`}
      >
        <div className="loading-innerwrap">
          <form
            onSubmit={handleCustomerGroupForm}
            className={`${
              customerGroupFormLoading
                ? "loading-blue full-width-form"
                : "full-width-form"
            }`}
          >
            <div className="form-innerwrap">
              <div className="form-group">
                <Input
                  placeholder="Our Top customers"
                  label="Customer Group Name"
                  // infoTooltip="This name will appear in the Quick Campaign section where you can target specific customers"
                  required
                  defaultValue={customerGroup.group_name}
                  getValue={(value) =>
                    handlesetCustomerGroup("group_name", value)
                  }
                />
              </div>
              <div className="form-group">
                <Textarea
                  label="Description"
                  placeholder="Sales team"
                  defaultValue={customerGroup.group_description}
                  getValue={(value) =>
                    handlesetCustomerGroup("group_description", value)
                  }
                />
              </div>
              <div className="form-group">
                <Checkbox isChecked={+customerGroup.group_discount > 0} label={'Use Default Discount for all products'} onChange={(checked)=>{
                  handlesetCustomerGroup("group_discount", checked ? 10 : 0)}}/>
              </div>
              {+customerGroup.group_discount > 0 && (<div className="form-group">
                <Input
                  placeholder="Discount"
                  label="Discount value"
                  type={'number'}
                  max={100}
                  min={0}
                  required
                  defaultValue={customerGroup.group_discount}
                  getValue={(value) =>
                    handlesetCustomerGroup("group_discount", +value < 0 ? 0 : +value > 100 ? 100 : value)
                  }
                />
              </div>)}
              
              <div className="manually-choosing-wrap">
                <div className="top">
                  <div className="title">Customers</div>
                  <button
                    type="button"
                    className="btn-blue-dashed"
                    onClick={() =>
                      setManualShowAllCustomer(!manualShowAllCustomer)
                    }
                  >
                    {manualShowAllCustomer
                      ? "Show choosen customers"
                      : "Show all customers"}
                  </button>
                  {manualShowAllCustomer ? <div className="search-wrap">
                    <Input
                      placeholder="Search..."
                      required
                      icon={<SearchIcon/>}
                      defaultValue={searchCustomers}
                      getValue={(value) =>
                        setSearchCustomers(value)
                      }
                    />
                  </div> : null}
                
                </div>
                <div className="main">
                  {manualShowAllCustomer ? (
                    <div
                      className={`all-customers ${
                        manualCustomerListLoading ? "loading-blue" : ""
                      }`}
                    >
                      <div className="form-innerwrap">
                      {manualCustomerList.length > 0 ? (
                          <>
                            <div className="table">
                              <DataTable
                                noHeader
                                columns={manualCustomerTableColumns}
                                data={manualCustomerList}
                              />
                            </div>
                            <div className="paginate-prepage">
                              <div className="paginate">
                                <button
                                  className="prev-page"
                                  type="button"
                                  disabled={manualCustomerListInfo.page <= 1}
                                  onClick={() =>
                                    handleManualCustomerPagination(
                                      manualCustomerListInfo.page - 1
                                    )
                                  }
                                >
                                  <ArrowBackIcon/>
                                </button>
                                <button
                                  className="next-page"
                                  type="button"
                                  disabled={
                                    manualCustomerListInfo.total_pages_count <=
                                    manualCustomerListInfo.page
                                  }
                                  onClick={() =>
                                    handleManualCustomerPagination(
                                      manualCustomerListInfo.page + 1
                                    )
                                  }
                                >
                                  <ArrowForwardIcon/>
                                </button>
                                <div className="text">
                                  {manualCustomerListInfo.page *
                                    manualCustomerListInfo.limit -
                                    manualCustomerListInfo.limit}
                                  -
                                  {manualCustomerListInfo.page *
                                    manualCustomerListInfo.limit -
                                    manualCustomerListInfo.limit +
                                    manualCustomerListInfo.count_users}{" "}
                                  from{" "}
                                  {manualCustomerListInfo.total_users_count}
                                </div>
                              </div>
                              <div className="perpage">
                                <div className="text">Show on page:</div>
                                <Select
                                  selectorList={customerPerPage}
                                  defaultValue={{
                                    value:
                                      manualCustomerListInfo.limit.toString(),
                                    label:
                                      manualCustomerListInfo.limit.toString(),
                                  }}
                                  positionForShow="top"
                                  required
                                  getValue={(value) => {
                                    handleManualCustomerPerPage(
                                      value.toString()
                                    );
                                  }}
                                  hideSelectorFilter
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="nothing-found">
                            Sorry, nothing found
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {manualCustomerChoosedList.length > 0 ? (
                        <div className="table">
                          <DataTable
                            noHeader
                            columns={manualChoosedCustomerTableColumns}
                            data={manualCustomerChoosedList}
                          />
                        </div>
                      ) : (
                        <div className="nothing-found">
                          Sorry, you need add customers
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              
              {customerGroupFormErrorMessage ? (
                <div className="form-error-message">
                  {customerGroupFormErrorMessage}
                </div>
              ) : null}
              <div className="buttons-wrap">
                <button
                  type="button"
                  className="btn-white"
                  onClick={() => {setShowCreateGroupForm(false); setSelectedGroup(null)}}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-blue">
                  {pageEdit ? "Update" : "Create"} Customer Group
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default CustomerGroups;
